<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">Rekapitulasi Nilai Pemda Kabupaten</h3>
        <div class="card-toolbar">

        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::Item-->
        <div class="mb-12">
          <!--begin::Content-->
          <div>
            <div class="row">
              <div class="col-xl-8">
              </div>
              <div class="col-xl-4">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Pilih Provinsi</label>
                  <!--begin::Input-->
                  <div class="col-sm-8">
                    <b-form-select v-model="selectedprovinsi" @input="getdata()">
                      <option v-for="(dataprovinsi, k) in provinsi" :key="k" :value="dataprovinsi.id">
                        {{ dataprovinsi.name }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </div>
            <div style="overflow-x:auto;">
              <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                  <tr>
                    <th rowspan="2">No</th>
                    <th rowspan="2">Nama Provinsi</th>
                    <th rowspan="2">Nama Kabupaten</th>
                    <th style="text-align:center;" colspan="4">Kinerja PTSP</th>
                    <th style="text-align:center;">Kinerja PPB</th>
                    <th rowspan="2">Nilai Akhir</th>
                    <th style="text-align:center;" colspan="4">Aktivitas Akun</th>
                    <th style="text-align:center;" colspan="2">Verifikasi Dokumentatif</th>
                    <th style="text-align:center;" colspan="2">Verifikasi Lapangan</th>
                    <th style="text-align:center;" colspan="2">File BA</th>
                    <th style="text-align:center;" colspan="3">Predikat</th>
                    <!-- <th colspan="3">Contact</th> -->
                  </tr>
                  <tr>
                    <th>PM PTSP</th>
                    <th>Penilaian Organisasi Pengusaha</th>
                    <th>Penilaian Provinsi</th>
                    <th>Total</th>
                    <th>PM PPB</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>Organisasi Pengusaha</th>
                    <th>PK Provinsi</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>PTSP</th>
                    <th>PPB</th>
                    <th>Akhir</th>
                  </tr>
                </thead>
                <tbody v-if="selectedprovinsi === ''">
                  <tr v-for="row in dataprovinsi1" :key="row.id">
                    <td></td>
                    <td>
                      <div style="width:150px">{{ row.provinsi }}</div>
                    </td>
                    <td>
                      <div style="width:150px">{{ row.daerah }}</div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_pm) }} &nbsp; &nbsp;<b-button
                          v-if="row.total_skor_pm != null" @click="urlpm(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_asosiasi) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_asosiasi != null" @click="urlhipmi(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_pk) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_pk != null" @click="urlppkprovinsi(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:80px">{{ format_desimal(row.total_ptsp) }}</div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_ppb) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_ppb != null" @click="urlppb(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:80px">{{ format_desimal(row.total_akhir) }}</div>
                    </td>
                    <td>
                      <div v-if="row.login_pm === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_pm === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_pm === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_ppb === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_ppb === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_ppb === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_asosiasi === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_asosiasi === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_asosiasi === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_pk === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_pk === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_pk === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_dok_pm === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_dok_pm === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_dok_ppb === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_dok_ppb === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_lap_pm === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_lap_pm === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_lap_ppb === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_lap_ppb === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td style="text-align:center">
                      <div style="width:120px" v-if="row.file_ba_pm == null || row.file_ba_pm === ''">

                      </div>
                      <div style="width:120px" v-else>
                        <a :href="row.file_ba_pm" target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                            class="fa fa-download" aria-hidden="true" /></a>
                      </div>
                    </td>
                    <td style="text-align:center">
                      <div style="width:120px" v-if="row.file_ba_ppb == null || row.file_ba_ppb === ''">

                      </div>
                      <div style="width:120px" v-else>
                        <a :href="row.file_ba_ppb" target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                            class="fa fa-download" aria-hidden="true" /></a>
                      </div>
                    </td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_ptsp }"
                        variant="success">{{ row.predikat_ptsp }}</b-badge></td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_ppb }"
                        variant="success">{{ row.predikat_ppb }}</b-badge></td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_akhir }"
                        variant="success">{{ row.predikat_akhir }}</b-badge></td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="row in dataprovinsi2" :key="row.id">
                    <td></td>
                    <td>
                      <div style="width:150px">{{ row.provinsi }}</div>
                    </td>
                    <td>
                      <div style="width:150px">{{ row.daerah }}</div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_pm) }} &nbsp; &nbsp;<b-button
                          v-if="row.total_skor_pm != null" @click="urlpm(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_asosiasi) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_asosiasi != null" @click="urlhipmi(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_pk) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_pk != null" @click="urlppkprovinsi(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:80px">{{ format_desimal(row.total_ptsp) }}</div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_ppb) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_ppb != null" @click="urlppb(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;" ><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:80px">{{ format_desimal(row.total_akhir) }}</div>
                    </td>
                    <td>
                      <div v-if="row.login_pm === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_pm === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_pm === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_ppb === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_ppb === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_ppb === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_asosiasi === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_asosiasi === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_asosiasi === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_pk === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_pk === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_pk === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_dok_pm === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_dok_pm === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_dok_ppb === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_dok_ppb === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_lap_pm === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_lap_pm === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_lap_ppb === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_lap_ppb === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td style="text-align:center">
                      <div style="width:120px" v-if="row.file_ba_pm == null || row.file_ba_pm === ''">

                      </div>
                      <div style="width:120px" v-else>
                        <a :href="row.file_ba_pm" target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                            class="fa fa-download" aria-hidden="true" /></a>
                      </div>
                    </td>
                    <td style="text-align:center">
                      <div style="width:120px" v-if="row.file_ba_ppb == null || row.file_ba_ppb === ''">

                      </div>
                      <div style="width:120px" v-else>
                        <a :href="row.file_ba_ppb" target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                            class="fa fa-download" aria-hidden="true" /></a>
                      </div>
                    </td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_ptsp }"
                        variant="success">{{ row.predikat_ptsp }}</b-badge></td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_ppb }"
                        variant="success">{{ row.predikat_ppb }}</b-badge></td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_akhir }"
                        variant="success">{{ row.predikat_akhir }}</b-badge></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>
<style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
<script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/dataTables.buttons.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.print.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.html5.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"></script>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
export default {
  data() {
      return {
        user: JSON.parse(localStorage.getItem('user')),
        test:'hai',
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        totskor1:[],
        totskor2:[],
        search: '',
        selectedprovinsi:'',
        provinsi:'',
        dataprovinsi1:[],
        dataprovinsi2:[],
        headers: [
            {
            text: 'Nama',
            align: 'left',
            sortable: false,
            value: 'name',
            },
            { text: 'Ket', value: 'ket' },
            { text: 'Icon', value: 'icon' },
            { text: 'Aksi', value: '' },
            
        ],
      }
  },
  components: {
    
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Rekapitulasi" }]);
    this.getdata()
    
  },
  methods: {
    getprovinsi(){
        axios.get(this.url+'/provinsis', {
              headers: {
                  'xth': this.token
              }
            }).then(response => {
            this.provinsi = response.data;
            this.provinsi.push({id:'', name:'Semua Provinsi',created_at:null,updated_at:null})
        });
      },
    getdata(){
      if (this.user.id_role === 'super_user' || this.user.id_role === 'tim_penilai' || this.user.id_role === 'operator_nomine' || this.user.id_role === 'pendamping_qc' || this.user.id_role === 'qc' || this.user.id_role === 'tim_teknis_penilai' || this.user.id_role === 'dpb' || this.user.id_role === 'kemenkeu' || this.user.id_role === 'expert') {
        $('#example').DataTable().destroy();
        let idprovinsi = this.selectedprovinsi;
              this.loaddata(idprovinsi)
      } else {
        this.$router.push({ name: "dashboard" })
      }
    },
    loaddata(idprovinsi){
      if (idprovinsi ==='') {
        this.dataprovinsi1 = null;
        axios.get(this.url+`/rekapitulasi_pemda/kabupaten?tahun=2024
        `, {
          headers: {
              'xth': this.token
          }
        })
        .then(response => {
            this.totskor1 = response.data;
            this.dataprovinsi1 = this.totskor1;
            // console.log(response.data)
            this.initDatatable1();
        }).catch(error=>{
            return error
            });
      } else {
        this.dataprovinsi2 = null;
        axios.get(this.url+`/rekapitulasi_pemda/kabupaten?prov=${idprovinsi}&tahun=2024`, {
          headers: {
              'xth': this.token
          }
        })
        .then(response => {
            this.totskor2 = response.data;
            this.dataprovinsi2 = this.totskor2;
            this.initDatatable2();
        }).catch(error=>{
            return error
            });
      }
    },
    initDatatable1() {
        setTimeout(() => {
        var t = $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            scrollX: true,
            fixedColumns:   {
                left: 2,
            },
            dom: 'Bfrtip',
            // "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            //     //debugger;
            //     var index = iDisplayIndexFull + 1;
            //     $("td:first", nRow).html(index);
            //     return nRow;
            // },
            // columnDefs: [{
            //     targets: 0,
            //     render: function(data, type, row, meta) {
            //     console.log(meta.row);
            //     console.log(type == 'export'? meta.row : data);
            //       return type == 'export'? meta.row + 1 : data;
            //     }
            // }],
            buttons: [
                {
                  extend: 'excel',
                    text : 'Excel',
                    title: 'REKAPITULASI PENILAIAN PEMDA KABUPATEN KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,21],
                        orthogonal: 'export',
                        format: {
                            header: function ( data, columnIdx ) {
                                if(columnIdx==9 || columnIdx==10 || columnIdx==11|| columnIdx==12){
                                return 'Aktivitas ' + data;
                                }
                                else if(columnIdx==13 || columnIdx==14){
                                  return 'Verifikasi Dokumentatif ' + data;
                                }
                                else if(columnIdx==15 || columnIdx==16){
                                  return 'Verifikasi Lapangan ' + data;
                                }
                                else if(columnIdx==17 || columnIdx==18){
                                  return 'File BA ' + data;
                                }
                                else if(columnIdx==19 || columnIdx==20 || columnIdx==21){
                                  return 'Predikat ' + data;
                                }
                                else{
                                  return data;
                                }
                            },
                            body: function(data, row, column, node) {
                                data = $('<p>' + data + '</p>').text();
                                return data.replace(',', '.');
                            }
        
                        }
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nilai Pemda Kabupaten ' + date;
                    },
                },
                {
                  extend: 'pdf',
                    text : 'PDF',
                    title: 'REKAPITULASI PENILAIAN PEMDA KABUPATEN KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nilai Pemda Kabupaten ' + date;
                    },
                }
            ],
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            order: [[8, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            // colReorder: true,

        });
        // t.on( 'order.dt search.dt', function () {
        // t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        // } ).draw();
        t.on('order.dt search.dt', function () {
          let i = 1;

          t.cells(null, 0, { search: 'applied', order: 'applied' }).every(function (cell) {
            this.data(i++);
          });
        }).draw();
        }, 300);
    },
    initDatatable2() {
        setTimeout(() => {
        var t = $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            scrollX: true,
            fixedColumns:   {
                left: 2,
            },
            dom: 'Bfrtip',
            "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //debugger;
                var index = iDisplayIndexFull + 1;
                $("td:first", nRow).html(index);
                return nRow;
            },
            columnDefs: [{
                targets: 0,
                render: function(data, type, row, meta) {
                console.log(meta.row);
                console.log(type == 'export'? meta.row : data);
                  return type == 'export'? meta.row + 1 : data;
                }
            }],
            buttons: [
                {
                  extend: 'excel',
                    text : 'Excel',
                    title: 'REKAPITULASI PENILAIAN PEMDA KABUPATEN KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                        orthogonal: 'export',
                        format: {
                            header: function ( data, columnIdx ) {
                                if(columnIdx==9 || columnIdx==10 || columnIdx==11){
                                return 'Aktivitas ' + data;
                                }
                                else if(columnIdx==12 || columnIdx==13){
                                  return 'Verifikasi Dokumentatif ' + data;
                                }
                                else if(columnIdx==14 || columnIdx==15){
                                  return 'Verifikasi Lapangan ' + data;
                                }
                                else if(columnIdx==16 || columnIdx==17){
                                  return 'File BA ' + data;
                                }
                                else if(columnIdx==18 || columnIdx==19 || columnIdx==20){
                                  return 'Predikat ' + data;
                                }
                                else{
                                  return data;
                                }
                            },
                            body: function(data, row, column, node) {
                                data = $('<p>' + data + '</p>').text();
                                return data.replace(',', '.');
                            }
        
                        }
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nilai Pemda Kabupaten ' + date;
                    },
                },
                {
                  extend: 'pdf',
                    text : 'PDF',
                    title : 'REKAPITULASI PENILAIAN PEMDA KABUPATEN KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nilai Pemda Kabupaten ' + date;
                    },
                }
            ],
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            order: [[8, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            // colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300);
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    urlpm(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailpm/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlppb(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailppb/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlhipmi(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailhipmi/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlppkprovinsi(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailppkprovinsi/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    this.getprovinsi()
    // this.loaddata()
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
